<template>
  <div class="cta-caja cta-caja--lila" @click="clicking">
    <div class="cta-caja__cover">
      <img
        class="cta-caja__cover-desk"
        src="../../../assets/img/covers/cover-cta-caja-verde.jpg"
        alt=""
      />
      <img
        class="cta-caja__cover-mobile"
        src="../../../assets/img/covers/cover-cta-caja-verde-m.jpg"
        alt=""
      />
    </div>
    <div class="cta-caja__content">
      <h4 class="cta-caja__title">
        Nuestros arrullos favoritos - Caja de regalo
      </h4>
      <div class="cta-caja__description">
        <p>Comodísimos para tu bebé, pero también para ti.</p>
      </div>
      <div class="cta-caja__actions">
        <loading-button v-if="loading" :class="'button-loading--sm'" />
        <button
          class="button button--dark button--sm button--ico"
          @click.stop="addProduct('Constructor - Banner Horitzontal')"
          :disabled="added"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <modal-dialog
        :show="showProductAddedDialog"
        @close="showProductAddedDialog = false"
        target-class="modal__card--mini"
    >
      <product-added-to-list/>
    </modal-dialog>
  </div>
</template>

<script>
import Product from "./Mixins/Product";
import LoadingButton from "../../../components/LoadingButton";
import ModalDialog from "../../../components/ui/ModalDialog";
import ProductAddedToList from "../../../components/ProductAddedToList";

export default {
  name: "CtaArrullos",
  mixins: [Product],
  components: {
    LoadingButton,
    ModalDialog,
    ProductAddedToList
  },
  computed: {
    buttonText() {
      return this.added ? "Añadido" : "Añadir a mi lista";
    },
  },
  created() {
    this.product = {
      id: 41823,
      "brand-slug": "caja-regalo-hellobb-primeros-cuentos",
      name: "Arrullando - Caja de regalo HelloBB",
      retailerName: "HelloBB",
      productRetailers: [{"name": "HelloBB"}]
    };
  },
  methods: {
    clicking()
    {
      mixpanel.track("Click element constructor", {
        "Name": "Nuestros arrullos favoritos - Caja de regalo",
        "Section": "Lo mejor para portear",
        "Section number": 11,
        "Type": "Banner - Horizontal",
        "Content": "Ecommerce"
      });
      this.browseProduct();
    }
  }
};
</script>
